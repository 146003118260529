.content{
  max-width: 100px;
  max-height: 100px;
  background-color:#006;
  margin-top:70px;
  margin-bottom:60px }
.k{
  max-width: 438px;
  max-height: 500px;
}
.image-viewer{
  max-width: 300px;
  max-height: 250px;
}
.image-style{
  width: 100%;
}
.fas{
  color: white;
}
.slider-container {
  width: 500px; /* Set the desired width */
  height: 400px; /* Set the desired height */
  margin: 0 auto;
}
.heading{
  color:#186b2e

}
.slider-image {
  object-fit: contain; /* Adjust the object-fit property as needed */
  max-width: 100%;
  max-height: 100%;
}
.background_1{
  position: absolute;
  opacity: 0.9;
  background-size: cover;
}
.footerColor{background-color:  #24873f
}
