body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.my-div {
  position: relative;
  width: 100%;
  height: 100vh;
}

.my-div img {
  display: block;
  width: 100%;
  height: 95%;
  object-fit: cover;
}

/* Media query for mobile screens */
@media (max-width: 767px) {
  .my-div {
    height: 50vh; /* Adjust the height as per your requirement */
  }
  .my-div .centered{
    top: 25%;
    left: 30%;
    
    transform: translate(-30%, -30%);
  }
  .my-div .centered .text-color{
    font-weight: normal;
  }
}
@media (max-width: 767px) {
  .my-div-about-us {
    height: 40vh; /* Adjust the height as per your requirement */
  }

}
.my-div-products {
  /* background-image: url(); */
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100vh;
}
.my-div-about-us {
  background-image: url("./data/home_screen_2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  width: 100%;
  height: 100vh;
}
.footer {
  left: 0;
  margin-bottom: 50px;
  width: 100%;
  text-align: center;
}
.centered {
  position: absolute;
  top: 30%;
  left: 35%;
  transform: translate(-30%, -30%);
}
.textColor{
  color:white;
  text-decoration: none;
}
.text-color{
  color:#186b2e;
  font-weight: bold;
  text-decoration: none;
}